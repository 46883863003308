// reducer.js
import { combineReducers } from 'redux';

import authReducer from './authReducer';
import viewReducer from './viewReducer';

const reducer = combineReducers({
    auth: authReducer,
    view: viewReducer,
});

export default reducer;
