import { SET_CURRENT_VIEW } from './actions';

const initialState = {
    currentView: 'send-invoice',
};

const viewReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_VIEW:
            return {
                ...state,
                currentView: action.payload,
            };
        default:
            return state;
    }
};

export default viewReducer;
